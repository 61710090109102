@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";


.customer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ($padding / 2) $padding-rem;
  background-color: $menu-color;
  border-bottom: 1px solid white;

  &-logo {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    img {
      height: 24px;
      margin: 1rem;
    }
  }
}
